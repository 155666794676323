import { MapSelectorTutorial } from '@wt/game/components/elements/MapSelector';
import fonts from '@wt/shared/assets/fonts';
import { getSiteMode } from '@wt/utilities/siteMode';
import { useEffect, useRef, useState } from 'react';

interface Clue {
  top: string;
  left: string;
  clue: string;
}

export const ExampleImage = ({ showAnswer }: { showAnswer: boolean }) => {
  const [activeClueIndex, setActiveClueIndex] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  let clues;
  switch (getSiteMode()) {
    case 'whentaken-movies':
      clues = [
        { top: '13.2%', left: '47.7%', clue: 'Washington Monument' },
        {
          top: '45.3%',
          left: '45.5%',
          clue: 'Reflecting pool at National Mall',
        },
        { top: '70.9%', left: '8.2%', clue: 'Vietnam War Protest Signs' },
        {
          top: '50.9%',
          left: '83.5%',
          clue: "Is this the back of Tom Hanks' head? Likely a scene from Forrest Gump...",
        },
        {
          top: '80.3%',
          left: '63.7%',
          clue: 'Military uniform',
        },
        {
          top: '92.6%',
          left: '17.0%',
          clue: 'Late 60s/early 70s clothing styles',
        },
      ];
      break;
    case 'wheretaken':
      clues = [
        {
          top: '23%',
          left: '41%',
          clue: 'Non-modern building means neighbourhood with a history',
        },
        { top: '41%', left: '84%', clue: 'German-speaking country' },
        { top: '49%', left: '94%', clue: 'Street name' },
        { top: '88%', left: '23%', clue: 'Berlin-looking tiles' },
      ];
      break;
    case 'whentaken':
    default:
      clues = [
        { top: '60%', left: '76%', clue: 'fashion from the 80-s' },
        { top: '52%', left: '48%', clue: 'red double-decker = London' },
        { top: '73%', left: '15%', clue: 'driving on the left side' },
        { top: '63%', left: '5%', clue: 'car looks more like 90-s' },
        {
          top: '20%',
          left: '18%',
          clue: 'old-style signs, probably the 70-s',
        },
        { top: '85%', left: '85%', clue: 'shot on film, not digital' },
        { top: '24%', left: '82%', clue: 'English-speaking country' },
        { top: '32%', left: '42%', clue: 'old buildings - city centre' },
      ];
      break;
  }

  const resetInterval = () => {
    if (intervalRef.current) clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setActiveClueIndex((prevIndex) => (prevIndex + 1) % clues.length);
    }, 3000);
  };

  useEffect(() => {
    resetInterval();
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  const handleClick = (index: number) => {
    if (window.innerWidth < 768) {
      setActiveClueIndex(index);
      if (intervalRef.current) clearInterval(intervalRef.current);
      resetInterval();
    }
  };

  const handleHover = (index: number) => {
    if (window.innerWidth > 768) {
      setActiveClueIndex(index);
      if (intervalRef.current) clearInterval(intervalRef.current);
    }
  };

  const handleLeave = () => {
    resetInterval();
  };

  let backgroundImage = 'url("/assets/images/london.jpg")';
  if (getSiteMode() == 'wheretaken') {
    backgroundImage = 'url("/assets/images/berlin.jpg")';
  } else if (getSiteMode() == 'whentaken-movies') {
    backgroundImage = 'url("/assets/images/forrest.jpg")';
  }

  return (
    <div className="flex w-full flex-col">
      <div className="relative flex h-12 w-full items-center justify-center md:hidden">
        {!showAnswer &&
          clues.map((clue, i) => (
            <MobileClueTag
              key={i}
              clue={clue}
              isActive={activeClueIndex === i}
            />
          ))}
      </div>
      <div
        className="relative flex aspect-[1.5] w-full overflow-hidden rounded-3xl bg-cover bg-center md:overflow-visible"
        style={{
          backgroundImage,
        }}
      >
        {!showAnswer &&
          clues.map((clue, i) => (
            <span
              key={i}
              className={`absolute z-10 flex size-4 items-center justify-center rounded-full border border-primary bg-primary-content movies:bg-[#FDF3CE] drop-shadow-lg transition-transform duration-300 ease-in-out ${i === activeClueIndex ? 'scale-150' : 'scale-100'} hover:cursor-pointer`}
              style={{
                top: clue.top,
                left: clue.left,
              }}
              onClick={() => handleClick(i)}
              onMouseEnter={() => handleHover(i)}
              onMouseLeave={handleLeave}
            >
              <span className="block size-[8px] rounded-full bg-primary" />
            </span>
          ))}
        <div className="hidden md:flex">
          {!showAnswer &&
            clues.map((clue, i) => (
              <DesktopClueTag
                key={i}
                clue={clue}
                isActive={activeClueIndex === i}
              />
            ))}
        </div>

        {showAnswer && (
          <>
            <MapAnswer />
            <YearAndDescriptionMobile />
            <YearAndDescriptionDesktop />
          </>
        )}
      </div>
    </div>
  );
};

const MobileClueTag = ({
  clue,
  isActive,
}: {
  clue: Clue;
  isActive: boolean;
}) => {
  return (
    <div
      className={`absolute flex rounded-2xl bg-primary-content movies:bg-[#FDF3CE] px-3 py-1 text-center text-sm text-primary movies:text-[#38362E] drop-shadow-lg transition-opacity duration-500 ease-in-out ${isActive ? 'opacity-100' : 'opacity-0'}`}
      style={fonts.gaegu.style}
    >
      {clue.clue}
    </div>
  );
};

const DesktopClueTag = ({
  clue,
  isActive,
}: {
  clue: Clue;
  isActive: boolean;
}) => {
  return (
    <div
      className={`absolute flex text-sm text-primary transition-opacity duration-500 ease-in-out ${isActive ? 'z-[11] opacity-100' : 'opacity-0'}`}
      style={{
        ...fonts.gaegu.style,
        bottom: `calc(100% - ${clue.top} + 5px)`,
        left: `calc(${clue.left} + 0.4rem)`,
      }}
    >
      <div
        className="rounded-2xl bg-primary-content movies:bg-[#FDF3CE] movies:text-[#38362E] px-3 py-1 drop-shadow-lg text-center relative max-w-[200px] mr-[-200px]"
        style={{
          translate: '-50% 0',
        }}
      >
        {clue.clue}
      </div>
    </div>
  );
};

const MapAnswer = () => {
  if (getSiteMode() == 'whentaken')
    return (
      <div className="absolute bottom-4 right-4 z-[11] flex size-16 items-center justify-center overflow-hidden rounded-xl border border-black shadow-lg md:size-36">
        <MapSelectorTutorial
          latitude={51.5142}
          longitude={-0.1504}
          label={''}
        />
      </div>
    );
  if (getSiteMode() == 'whentaken-movies')
    return (
      <div className="absolute bottom-4 right-4 z-[11] flex size-16 items-center justify-center overflow-hidden rounded-xl border border-black shadow-lg md:size-36">
        <MapSelectorTutorial
          latitude={38.889284}
          longitude={-77.048826}
          label={''}
        />
      </div>
    );
  return (
    <div className="absolute bottom-4 right-4 z-[11] flex size-16 items-center justify-center overflow-hidden rounded-xl border border-black shadow-lg md:size-36">
      <MapSelectorTutorial
        latitude={52.5477943}
        longitude={13.4132655}
        label={''}
      />
    </div>
  );
};

const year = getSiteMode() == 'whentaken' ? 1989 : 1969;
const description = {
  whentaken: <>London, west on Oxford Street at James Street.</>,
  'whentaken-movies': <>National Mall, Washington, D.C.</>,
  wheretaken: (
    <>
      <strong>Berlin</strong>, looking West on the intersection between
      Stargarden Straße and Schönhauser Allee.
    </>
  ),
}[getSiteMode()];

const YearAndDescriptionMobile = () => {
  if (getSiteMode() == 'wheretaken')
    return (
      <div className="absolute bottom-0 z-10 flex min-h-24 w-full shrink flex-col justify-center rounded-b-md bg-black/40 p-2 pr-24 text-white md:hidden">
        <div className="mt-1 basis-0 text-md leading-tight">{description}</div>
      </div>
    );

  return (
    <div className="absolute bottom-0 z-10 flex min-h-24 w-full shrink flex-col rounded-b-md bg-black/40 p-2 pr-24 text-white md:hidden">
      <div className="text-xl font-semibold">{year}</div>
      <div className="mt-1 flex w-full grow basis-0 flex-col items-start justify-center gap-1.5 border-t border-primary-content">
        <div className="mt-1 basis-0 text-sm leading-tight">{description}</div>
      </div>
    </div>
  );
};

const YearAndDescriptionDesktop = () => {
  if (getSiteMode() == 'wheretaken')
    return (
      <div className="absolute bottom-4 right-40 mx-4 hidden flex-row items-center gap-3 rounded-lg bg-black/40 p-4 text-white md:flex">
        <div className="border-l-4 border-base-200 pl-3 text-base">
          {description}
        </div>
      </div>
    );
  return (
    <div className="absolute bottom-4 right-40 mx-4 hidden flex-row items-center gap-3 rounded-lg bg-black/40 p-4 text-white md:flex">
      <div className="text-xl font-bold lg:text-3xl">{year}</div>
      <div className="border-l-4 border-base-200 pl-3 text-base">
        {description}
      </div>
    </div>
  );
};
