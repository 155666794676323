'use client';

import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { MobileFooterMenu } from '@wt/game/components/elements/footer';
import fonts from '@wt/shared/assets/fonts';
import { getFeedbackLink } from '@wt/utilities/feedbackLink';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { ExampleImage } from './ExampleImage';
import FAQ from './FAQ';
import GameButtons from './GameButtons';
import { HowToPlaySection } from './how-to-play/HowToPlayerComponents';
import { getSiteMode } from '@wt/utilities/siteMode';
import WheretakenClassicModal from '@wt/game/modals/WheretakenClassicModal';

export default function HomePage() {
  useEffect(() => {
    if (window.location.hash == '#faq') {
      const element = document.getElementById('faq');
      if (element) {
        element.scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        });
      }
    }
  }, []);

  const [showAnswer, setShowAnswer] = useState<boolean>(false);

  return (
    <>
      {getSiteMode() == 'wheretaken' && <WheretakenClassicModal />}
      <div className="flex size-full flex-1 grow flex-col items-center justify-center gap-12 py-4 md:gap-[60px] md:py-6">
        <div className="flex w-full max-w-[1252px] flex-col gap-6 md:gap-9">
          <div className="hidden w-full max-w-[1252px] gap-6 px-8 md:flex">
            <div className="flex w-full flex-col justify-between gap-4">
              <InfoText />
              <ShowHideAnswerSection
                showAnswer={showAnswer}
                setShowAnswer={setShowAnswer}
              />
            </div>
            <ExampleImage showAnswer={showAnswer} />
          </div>
          <div className="flex px-4 md:hidden">
            <InfoText />
          </div>
          {getSiteMode() == 'whentaken' && (
            <div className="flex justify-center">
              <div className="flex flex-col md:flex-row items-center justify-center bg-[#FFEECC] rounded-3xl mb-3 py-3 px-9 mx-2.5">
                <div className="text-[#4B4554]">
                  Stuck? Our new hint feature is designed to help you solve the
                  toughest puzzles.
                </div>
                <Link
                  href="#what-are-hints-beta-and-how-do-they-work"
                  className="mt-3 md:mt-0"
                >
                  <div className="flex items-center justify-center bg-[#FFCC6A] text-center font-semibold text-[#322E38] rounded-2xl px-4 py-2 md:ml-4 w-[170px]">
                    Learn more
                    <div className="items-center justify-center bg-white shadow-2xl rounded-xl p-1 ml-4">
                      <LightbulbOutlinedIcon htmlColor="black" />
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )}
          <GameButtons />
          <div className="flex w-full flex-col gap-6 px-4 md:hidden">
            <ExampleImage showAnswer={showAnswer} />
            <ShowHideAnswerSection
              showAnswer={showAnswer}
              setShowAnswer={setShowAnswer}
            />
          </div>
        </div>
        <div className="flex max-w-[820px] flex-col gap-12 px-4 md:w-3/4 md:gap-[60px] md:px-8">
          <HowToPlaySection />
          <FAQ />
          <FeedbackSection />
        </div>
        <div className="px-4 md:hidden">
          <MobileFooterMenu />
        </div>
      </div>
    </>
  );
}

const ShowHideAnswerSection = ({
  showAnswer,
  setShowAnswer,
}: {
  showAnswer: boolean;
  setShowAnswer: (e: boolean) => void;
}) => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 rounded-2xl bg-base-200 p-6 md:flex-row">
      <span
        className="text-center text-base md:text-2xl"
        style={fonts.gaegu.style}
      >
        You think you know the answer?
      </span>
      <button
        onClick={() => setShowAnswer(!showAnswer)}
        className="btn btn-primary h-[40px] min-h-[40px] w-fit min-w-[165px] shrink rounded-xl text-sm font-normal md:text-base"
      >
        <span className="pointer-events-none whitespace-nowrap">
          {showAnswer ? (
            <VisibilityOffOutlinedIcon />
          ) : (
            <VisibilityOutlinedIcon />
          )}{' '}
          {showAnswer ? 'Hide answer' : 'Show answer'}
        </span>
      </button>
    </div>
  );
};

const InfoText = () => {
  return (
    <div className="flex w-full flex-col gap-4">
      <span className="text-2xl font-semibold uppercase md:text-5xl">
        {getSiteMode() == 'whentaken' && (
          <>
            GUESS{' '}
            <span className="text-highlight dark:text-highlight-dark">
              WHEN
            </span>{' '}
            AND{' '}
            <span className="text-highlight dark:text-highlight-dark">
              WHERE
            </span>{' '}
            A PHOTO WAS TAKEN
          </>
        )}
        {getSiteMode() == 'whentaken-movies' && (
          <>
            GUESS{' '}
            <span className="text-highlight dark:text-highlight-dark">
              WHEN
            </span>{' '}
            AND{' '}
            <span className="text-highlight dark:text-highlight-dark">
              WHERE
            </span>{' '}
            A FILM WAS SET
          </>
        )}
        {getSiteMode() == 'wheretaken' && (
          <>
            GUESS{' '}
            <span className="text-highlight dark:text-highlight-dark">
              WHERE
            </span>{' '}
            A PHOTO WAS TAKEN
          </>
        )}
      </span>
      <span className="text-sm md:text-base">
        {getSiteMode() == 'whentaken' && (
          <>
            Test your detective skills with our daily game, check out the
            archive or create your own game in the Community section.
          </>
        )}
        {getSiteMode() == 'wheretaken' && (
          <>
            Test your detective skills with our daily game or check out the
            archive.
          </>
        )}
        {getSiteMode() == 'whentaken-movies' && (
          <>
            Test your movie knowledge and detective skills by guessing the year
            and location in which a movie scene was set.
          </>
        )}
      </span>
    </div>
  );
};

const FeedbackSection = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-6">
      <span className="text-2xl font-semibold md:text-5xl">💌 Feedback</span>
      <div className="flex w-full flex-col rounded-2xl bg-base-200 p-6 md:flex-row">
        <div className="text-sm md:w-1/2 md:text-base">
          Something not working properly? Want to suggest a new feature? Simply
          love the game and want to give us kudos? Fill out the feedback form to
          let us know what's on your mind.
        </div>
        <div className="my-5 h-[2px] w-full bg-secondary md:mx-5 md:my-0 md:h-full md:w-[2px]"></div>
        <div className="flex flex-col items-center justify-center gap-4 md:w-1/2">
          <span
            className="text-center text-base md:text-2xl"
            style={fonts.gaegu.style}
          >
            Let us know what you think 😉
          </span>
          <Link
            href={getFeedbackLink()}
            target="_blank"
            className="btn h-[40px] min-h-[40px] w-fit shrink rounded-xl border border-gray-400 bg-base-300 text-sm font-normal text-base-100 hover:bg-base-300/80 md:text-base"
          >
            Submit Feedback
          </Link>
        </div>
      </div>
    </div>
  );
};
