export default [
  {
    question: 'When is a new game available?',
    answer: (
      <p className="text-sm md:text-base">
        A new game is available every day at 0:00 am, local time of your device!
      </p>
    ),
  },
  {
    question: 'Can I play past games?',
    answer: (
      <p className="text-sm md:text-base">
        Yes, you can head to the Archive section from the menu and play all of
        the WhereTaken archive games starting from the 28th of October 2024.
      </p>
    ),
  },
  {
    question: 'What are the scoring rules?',
    answer: (
      <div className="flex flex-col gap-3 text-sm md:text-base">
        <div>
          <span className="font-bold">Scoring system</span>
          <ul className="mt-2 flex flex-col gap-1 md:ml-2">
            <p>• 5 rounds will get you a maximum of 1000 points.</p>
          </ul>
        </div>
        <div>
          <span className="font-bold">Scoring for guessing the location</span>
          <ul className="mt-2 flex flex-col gap-1 md:ml-2">
            <p>
              • The points will be awarded based on distance from the correct
              answer.
            </p>
            <p>
              • For each distance bracket, the value of 1 km will be different,
              meaning that the farther the guess is, the fewer maximum points
              the player can get.
            </p>
            <div>
              • Brackets:
              <div className="ml-4 mt-2 flex flex-col gap-1">
                <p>
                  • <span className="font-semibold">0-100</span> km: min{' '}
                  <span className="font-semibold">190 points</span>, max{' '}
                  <span className="font-semibold">200 points</span>, decrease by
                  0.1 points per km
                </p>
                <p>
                  • <span className="font-semibold">101-500</span> km: min{' '}
                  <span className="font-semibold">170 points</span>, max{' '}
                  <span className="font-semibold">190 points</span>, decrease by
                  0.05 points per km
                </p>
                <p>
                  • <span className="font-semibold">501-1000</span> km: min{' '}
                  <span className="font-semibold">140 points</span>, max{' '}
                  <span className="font-semibold">170 points</span>, decrease by
                  0.06 points per km
                </p>
                <p>
                  • <span className="font-semibold">1001-2000</span> km: min{' '}
                  <span className="font-semibold">100 points</span>, max{' '}
                  <span className="font-semibold">140 points</span>, decrease by
                  0.04 points per km
                </p>
                <p>
                  • <span className="font-semibold">2001 - 5000</span> km: min{' '}
                  <span className="font-semibold">40 points</span>, max{' '}
                  <span className="font-semibold">100 points</span>, decrease by
                  0.02 points per km
                </p>
                <p>
                  • <span className="font-semibold">5001 - 10000</span> km: min{' '}
                  <span className="font-semibold">0 points</span>, max{' '}
                  <span className="font-semibold">40 points</span>, decrease by
                  0.008 points per km
                </p>
                <p>
                  • <span className="font-semibold">≥ 10001</span> km:{' '}
                  <span className="font-semibold">0 points</span>
                </p>
              </div>
            </div>
            <p>
              • For the guessed distance within a bracket, calculate the points
              by subtracting the appropriate decrement per kilometre from the
              max points of that bracket.
            </p>
            <p>
              • <span className="font-semibold">Final formula</span>: Location
              Points = Max Points for Bracket - ((Distance in km - Bracket
              Starting Distance) &times; Point Decrement per km)
            </p>
          </ul>
        </div>
        <p className="italic">
          Example: The correct location is Kyiv, and the player guesses Odesa.
          The location points are calculated this way: the distance between Kyiv
          and Odesa is 475 km, which falls into the 101-500 km bracket, so the
          max number of points the user can get is 95. Calculations: Location
          Points = 95 - ((475 - 101) &times; 0.025) = 90 - (374 &times; 0.025) =
          95 - 9.35 = 85.65 points. Rounding this to the nearest whole number,
          it would be 86 points.
        </p>
      </div>
    ),
  },
  {
    question: 'Why is your location incorrect?',
    answer: (
      <div className="flex flex-col gap-2 text-sm md:text-base">
        <p>
          We're doing our best to collect the most accurate data to provide you
          with the best gaming experience - we even use Street View to find the
          most accurate coordinates, however, sometimes we make mistakes or
          can't find the exact location, so bear with us. Another reason why the
          location might be slightly off is that we were unable to find the
          exact location and instead used the city coordinates.
        </p>
        <p>
          If you find a mistake, please, report it and suggest another set of
          coordinates through the form by clicking on the ⚠️ button. We really
          appreciate the input from locals who help us make our data more
          precise.
        </p>
      </div>
    ),
  },
  {
    question: 'What games contribute to my stats?',
    answer: (
      <p className="text-sm md:text-base">
        Only the daily game contributes to your stats. Archive games will not
        affect your stats.
      </p>
    ),
  },
];
