'use client';

import { InfoBlock } from '@wt/app/_components/how-to-play/InfoBlock';
import { MapSelectorTutorial } from '@wt/game/components/elements/MapSelector';
import { YearSlider } from '@wt/shared/components/yearslider';
import { getSiteMode } from '@wt/utilities/siteMode';
import Link from 'next/link';
import { memo } from 'react';

export const HowToPlaySection = () => {
  return (
    <div className="flex w-full flex-col items-center justify-center gap-4 p-2 md:gap-6 md:rounded-2xl md:bg-base-200 md:p-6">
      <span className="text-2xl font-semibold md:text-5xl">How to play</span>
      <div className="hidden w-full border-b-2 border-secondary md:flex" />
      <HowToPlayerComponents />
    </div>
  );
};

const HowToPlayerComponents = memo(() => {
  if (getSiteMode() != 'wheretaken') {
    const backgroundImage =
      getSiteMode() == 'whentaken-movies'
        ? 'url("/assets/images/missionimpossible.jpg")'
        : 'url("/assets/images/parisolympics.jpg")';
    const longlat =
      getSiteMode() == 'whentaken-movies'
        ? {
            latitude: 41.90599255620884,
            longitude: 12.482797263163327,
          }
        : {
            latitude: 48.862356,
            longitude: 2.288253,
          };
    const year = getSiteMode() == 'whentaken-movies' ? 2020 : 2017;
    return (
      <div className=":max-w-[90%] relative h-auto max-w-full shrink">
        <div className="info-blocks transition-all duration-200 ease-in-out">
          <InfoBlock
            title="Look at the photo and try to guess the location and the year when it
            was taken."
          >
            <div
              className="relative block aspect-[5/3] w-full overflow-hidden rounded-xl bg-cover bg-center drop-shadow-2xl"
              style={{
                backgroundImage,
              }}
            />
          </InfoBlock>
          <div className="w-full border-b-2 border-secondary md:hidden" />

          <InfoBlock title="Place your pin on the map.">
            <div className="block aspect-[5/3] w-full overflow-hidden rounded-xl bg-base-100 drop-shadow-2xl">
              <MapSelectorTutorial
                latitude={longlat.latitude}
                longitude={longlat.longitude}
              />
            </div>
          </InfoBlock>
          <div className="w-full border-b-2 border-secondary md:hidden" />

          <InfoBlock title="Move the slider to select the year or simply type it in.">
            <YearSlider selectedYear={year} />
          </InfoBlock>
          <div className="w-full border-b-2 border-secondary md:hidden" />

          <InfoBlock title="When you’re ready, press the button to make your guess.">
            <Link
              href={'/game'}
              prefetch={true}
              className="btn btn-primary w-full shrink text-lg font-bold uppercase text-primary-content md:h-[61px] lg:text-2xl"
            >
              GUESS
            </Link>
          </InfoBlock>
        </div>
      </div>
    );
  } else
    return (
      <div className=":max-w-[90%] relative h-auto max-w-full shrink">
        <div className="info-blocks transition-all duration-200 ease-in-out">
          <InfoBlock title="Look at the photo and try to guess the location.">
            <div
              className="relative block aspect-[5/3] w-full overflow-hidden rounded-xl bg-cover bg-center drop-shadow-2xl"
              style={{
                backgroundImage: 'url("/assets/images/fuji.jpg")',
              }}
            />
          </InfoBlock>
          <div className="w-full border-b-2 border-secondary md:hidden" />

          <InfoBlock title="Place your pin on the map.">
            <div className="block aspect-[5/3] w-full overflow-hidden rounded-xl bg-base-100 drop-shadow-2xl">
              <MapSelectorTutorial
                latitude={35.5011703352097}
                longitude={138.8014703439077}
              />
            </div>
          </InfoBlock>
          <div className="w-full border-b-2 border-secondary md:hidden" />

          <InfoBlock
            title="When you’re ready, press the button to make your guess."
            wideColumn={true}
          >
            <Link
              href={'/game'}
              prefetch={true}
              className="btn btn-primary shrink text-lg font-bold uppercase text-white md:h-[61px] w-1/2 lg:text-2xl"
            >
              GUESS
            </Link>
          </InfoBlock>
        </div>
      </div>
    );
});
